<template>
	<div class="content">
		<Header :title="$t('lang.information_title')"></Header>
		<!-- 页面开始 -->
		<div class="make">
			<div class="information-li">
				<ul class="title-li">
					<li :class="num==index?'active':''" v-for="(item, index) in column" @click="switchNum(item.id), num=index">{{ item.columnName }}</li>
				</ul>
			</div>
			<div class="information-div" v-show="num==0">
				<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
					<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
						<div class="list-box" v-for="(item, index) in list" :key="index" @click="goDetails(item.id)">
							<img :src="item.coverPicture" alt="" />
							<div class="list-text">
								<h4>{{item.title}}</h4>
								<p>{{item.author}}：{{item.createTime}}</p>
							</div>
						</div>
					</van-list>
				</van-pull-refresh>
			</div>
			<div class="information-div" v-show="num==1">
				<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
					<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
						<div class="list1-box" v-for="(item, index) in list" :key="index" @click="goDetails(item.id)">
							<img :src="item.coverPicture" alt="" />
							<div class="list-text">
								<h4>{{item.title}}</h4>
								<p>{{item.author}}：{{item.createTime}}</p>
							</div>
						</div>
					</van-list>
				</van-pull-refresh>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from "@/components/Header";
	import HB from "@/assets/js/bridge";
	import { getAppColumn, getAppConsult } from "@/assets/api/informationApi.js";
	
	export default {
		components: {
			Header
		},
		data() {
			return {
				language: '',
				column: [],	// 栏目列表
				cid: '',
				pageNum: 1,
				num: '0',
				list: [],
				list1: [],
				loading: false,
				finished: false,
				refreshing: false,
			}
		},
		created() {
			// url获取参数
			this.language = this.$route.query.language;
			localStorage.setItem('lang', this.language);
			this.$i18n.locale = localStorage.getItem("lang");
		},
		mounted() {
			this.getAppColumn();
		},
		methods: {
			switchNum(cid) {
				this.cid = cid;
				this.loading = false;
				this.finished = false;
				this.list = [];
				this.pageNum = 1;
				this.getAppConsult();
			},
			// 栏目列表
			getAppColumn() {
				getAppColumn().then((res) => {
					// console.log(res, '栏目列表');
					this.column = res.data;
					this.cid = res.data[0].id;
					this.getAppConsult();	// 资讯列表
				})
			},
			// 资讯列表
			getAppConsult() {
				getAppConsult({
					cid: this.cid,
					pageNum: this.pageNum
				}).then((res) => {
					// console.log(res, '资讯列表');
					if (res.code == 0) {
						if (this.pageNum == 1) {
							this.list = res.data;
						} else {
							// 如果不是则在后面追加数据,forEach()方法         
							res.data.forEach(item => {
								this.list.push(item);
							});
							// 追加完成后关闭loading
							this.loading = false;
						}
						if (res.data.length > 0) {
							this.pageNum ++;
							this.loading = false;
						} else {
							// 如果没有数据加载完毕
							this.finished = true;
						}
					} else{
						this.Toast(res.message);
					}
				})
			},
			// 上拉刷新,下拉加载
			onLoad() {
				setTimeout(() => {        
					this.getAppConsult();      
				}, 2000);
			},
			// 下拉刷新    
			onRefresh() {
				setTimeout(() => {
					this.pageNum = 1;
					this.getAppConsult();
					this.refreshing = false;
				}, 2000);
			},
			// 跳转详情
			goDetails(id) {
				this.$router.push({
					path: '/informationDetails',
					query: {
						id: id
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.content {
		width: 100%;
		height: 100%;
		overflow: hidden;
		overflow-y: auto;
		background-color: #F7F8FA;
	}
	.make {
		width: 90%;
		margin: 0 auto;
	}
	// tab切换标题
	.information-li {
		width: 90%;
		margin: 0 auto;
		background-color: #F7F8FA;
		position: absolute;
		z-index: 99;
		padding-top: 0.3rem;
	}
	.title-li {
		background-color: #F7F8FA;
		display: flex;
		border: 1px solid #707070;
		border-radius: 5px;
		li {
			width: 3.45rem;
			height: 0.88rem;
			font-size: 0.28rem;
			line-height: 0.88rem;
			font-weight: 500;
			color: #333333;
			text-align: center;
		}
		.active {
			color: #FFFFFF;
			background-color: #03246C;
		}
	}
	.information-div {
		padding-top: 1.2rem;
	}
	// tab切换内容
	.list-box {
		display: flex;
		align-items: center;
		margin-top: 0.3rem;
		img {
			width: 2.36rem;
			height: 1.84rem;
			border-radius: 10px;
		}
		.list-text {
			margin-left: 0.2rem;
			h4 {
				font-size: 0.28rem;
				font-weight: 600;
				color: #333333;
				// 文字超出2行显示...
				overflow: hidden; 
				text-overflow: ellipsis;
				display: -webkit-box; 
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
			}
			p {
				font-size: 0.24rem;
				font-weight: 400;
				color: #999999;
				margin-top: 0.1rem;
			}
		}
	}
	.list1-box {
		height: 3.87rem;
		margin-top: 0.3rem;
		background-color: #FFFFFF;
		box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.04);
		border-radius: 10px;
		img {
			width: 100%;
			height: 2.64rem;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
		}
		.list-text {
			width: 90%;
			margin: 0 auto;
			h4 {
				font-size: 0.28rem;
				font-weight: 600;
				color: #333333;
				// 文字超出1行显示...
				overflow: hidden; 
				text-overflow: ellipsis;
				display: -webkit-box; 
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
			}
			p {
				font-size: 0.24rem;
				line-height: 0.48rem;
				font-weight: 400;
				color: #999999;
			}
		}
	}
</style>
